// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DOCS = '/docs';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  visa: {
    root: path(ROOTS_DASHBOARD, '/visa'),
    country: path(ROOTS_DASHBOARD, '/visa/country'),
    typeOfVisa: path(ROOTS_DASHBOARD, '/visa/type-of-visa-tabs'),
    new: path(ROOTS_DASHBOARD, '/visa/country/add-new-country'),
    visa: path(ROOTS_DASHBOARD, '/visa/country/add-country-visa'),
    DataVariables: path(ROOTS_DASHBOARD, '/visa/data-variables'),
    DocumentsRequired: path(ROOTS_DASHBOARD, '/visa/documents-required'),
    faq: path(ROOTS_DASHBOARD, '/visa/faq'),
    travelAdvice: path(ROOTS_DASHBOARD, '/visa/travel-advice'),
    customsInfo: path(ROOTS_DASHBOARD, '/visa/customs-info'),
    addDataVariables: path(ROOTS_DASHBOARD, '/visa/data-variables/add-data-variables'),
  },
  checkMyvisa: {
    root: path(ROOTS_DASHBOARD, '/check-visa'),
    checkvisa: path(ROOTS_DASHBOARD, '/check-visa/check-my-visa'),
    checkMyVisaDocumentsRequired: path(ROOTS_DASHBOARD, '/check-visa/documents-required'),
    checkMyVisaDataForm: path(ROOTS_DASHBOARD, '/check-visa/add-check-my-visa-data'),
    documents: path(ROOTS_DASHBOARD, '/check-visa/visa-documents'),
    faq: path(ROOTS_DASHBOARD, '/check-visa/faq'),
  },
  prPathway: {
    root: path(ROOTS_DASHBOARD, '/pr-pathway'),
    new: path(ROOTS_DASHBOARD, '/pr-pathway/pr-pathway-listing/add-new-pr-pathway'),
    prPathwayDetails: path(ROOTS_DASHBOARD, '/pr-pathway/pr-pathway-listing/pr-pathway-details'),
    PRDataVariables: path(ROOTS_DASHBOARD, '/pr-pathway/pr-pathway-data-variables'),
    PRDocuments: path(ROOTS_DASHBOARD, '/pr-pathway/pr-pathway-document-points'),
    PRPathwayDocumentsRequired: path(ROOTS_DASHBOARD, '/pr-pathway/pr-pathway-documents-required'),
    PRDetailsForm: path(ROOTS_DASHBOARD, '/pr-pathway/pr-pathway-listing/pr-details-form'),
    prOccupation:path(ROOTS_DASHBOARD,'/pr-pathway/occupation-list'),
    faq:path(ROOTS_DASHBOARD,'/pr-pathway/faq'),
  },
  insurance: {
    root: path(ROOTS_DASHBOARD, '/insurance'),
    insuranceListing: path(ROOTS_DASHBOARD, '/insurance/insurance-listing'),
    whyYouChoose: path(ROOTS_DASHBOARD, '/insurance/why-you-choose'),
    chooseRightCover: path(ROOTS_DASHBOARD, '/insurance/choose-right-cover'),
    faq: path(ROOTS_DASHBOARD, '/insurance/faq'),
    benefits: path(ROOTS_DASHBOARD, '/insurance/add-benefits'),
    new: path(ROOTS_DASHBOARD, '/insurance/insurance-listing/add-new-insurance'),
    insuranceDetails: path(ROOTS_DASHBOARD, '/insurance/insurance-listing/insurance-details'),
    enquiries: path(ROOTS_DASHBOARD, '/insurance/enquiries'),
  },
  universities: {
    root: path(ROOTS_DASHBOARD, '/universities'),
    universityListing: path(ROOTS_DASHBOARD, '/universities/university-listing'),
    enquiryListing: path(ROOTS_DASHBOARD, '/universities/enquiry-listing'),
    DocumentsRequired: path(ROOTS_DASHBOARD, '/universities/documents-required'),
    AllAboutTheCourse: path(ROOTS_DASHBOARD, '/universities/all-about-course'),
    faq: path(ROOTS_DASHBOARD, '/universities/faq'),
    new: path(ROOTS_DASHBOARD, '/universities/university-listing/add-new-university'),
    universityDetails: path(ROOTS_DASHBOARD, '/universities/university-listing/university-details'),
    courseDetails: path(ROOTS_DASHBOARD, '/universities/university-listing/university-details/course-details'),
  },
  consultants: {
    root: path(ROOTS_DASHBOARD, '/consultants'),
    consultantsListing: path(ROOTS_DASHBOARD, '/consultants/consultants-listing'),
    consultantsStaff: path(ROOTS_DASHBOARD, '/consultants/consultants-staffs'),
    serviceLocation: path(ROOTS_DASHBOARD, '/consultants/service-locations'),
    addNewserviceLocation: path(ROOTS_DASHBOARD, '/consultants/service-locations/add-service-location'),
    offers: path(ROOTS_DASHBOARD, '/consultants/offers'),
    faq: path(ROOTS_DASHBOARD, '/consultants/faq'),
    new: path(ROOTS_DASHBOARD, '/consultants/consultants-listing/add-new-consultants'),
    consultantsDetails: path(ROOTS_DASHBOARD, '/consultants/consultants-listing/consultants-details'),
  },
  languages: {
    root: path(ROOTS_DASHBOARD, '/languages'),
    languagesListing: path(ROOTS_DASHBOARD, '/languages/languages-listing'),
    new: path(ROOTS_DASHBOARD, '/languages/languages-listing/add-new-languages'),
    languageDetails: path(ROOTS_DASHBOARD, '/languages/languages-listing/languages-details'),
    serviceLocation: path(ROOTS_DASHBOARD, '/languages/service-locations'),
    addNewserviceLocation: path(ROOTS_DASHBOARD, '/languages/service-locations/add-service-location'),
    offers: path(ROOTS_DASHBOARD, '/languages/offers'),
    faq: path(ROOTS_DASHBOARD, '/languages/faq'),
    languageStaff: path(ROOTS_DASHBOARD, '/languages/languages-staffs'),
    enquiries: path(ROOTS_DASHBOARD, '/languages/enquiry'),
  },
  credits: {
    root: path(ROOTS_DASHBOARD, '/credits'),
    creditsListing: path(ROOTS_DASHBOARD, '/credits/credits-listing'),
    enquiryListing: path(ROOTS_DASHBOARD, '/credits/enquiry-listing'),
    DocumentsRequired: path(ROOTS_DASHBOARD, '/credits/documents-required'),
    CreditsDataVariables: path(ROOTS_DASHBOARD, '/credits/data-variables'),
    faq: path(ROOTS_DASHBOARD, '/credits/faq'),
    consultantLocation: path(ROOTS_DASHBOARD, '/credits/consultant-near-locations'),
    addConsultantLocation: path(ROOTS_DASHBOARD, '/credits/add-consultant-near-locations'),
    new: path(ROOTS_DASHBOARD, '/credits/credits-listing/add-new-bank'),
    creditsDetails: path(ROOTS_DASHBOARD, '/credits/credits-listing/credits-details'),
    loanDetails: path(ROOTS_DASHBOARD, '/credits/credits-listing/credits-details/loan-details'),
  },
  enquiries: {
   root:path(ROOTS_DASHBOARD, '/enquiries/website-enquiry'),
   partner:path(ROOTS_DASHBOARD, '/enquiries/partner-enquiry'),
   serviceList:path(ROOTS_DASHBOARD, '/enquiries/service-enquiry'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/portfolio-review-is-this-portfolio-too-creative'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog')
};
